import { Controller } from "stimulus";

export default class extends Controller {
    connect() {
        const active_modal = document.querySelector('.modal.show');

        if (active_modal) {
            // This is custom implementation for $(active_modal)?.modal('hide') which is not delete modal-open class on the body
            active_modal.classList.remove('show');
            active_modal.style.display = 'none';
            active_modal.setAttribute('aria-hidden', 'true');
            active_modal.removeAttribute('aria-modal');
            active_modal.removeAttribute('role');

            const modalBackdrop = document.querySelector('.modal-backdrop')

            if (modalBackdrop) {
                modalBackdrop.classList.remove('show'); // Add fade-out animation
                setTimeout(() => modalBackdrop.remove(), 150); // Remove after animation
            }
        }
    }
}
