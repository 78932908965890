import PaymentController from "./payment_controller.js";

export default class extends PaymentController {
  static targets = [
    "datatransForm",
    "cardNumber",
    "cardCVV",
    "newCardContainer",
    "newCardInput",
    "newCardDetails",
    "paymentMethod",
    "paymentMethodContainer",
    "submit",
    "cardBrand",
    "cardMonth",
    "cardYear",
  ];

  connect() {
    this.inputFocusHandler();

    this.cardNumberTarget.addEventListener("input", (event) => {
      event.target.value = event.target.value.replace(/\s/g, "").substring(0, 16);
    });

    window.Parsley.setLocale(this.locale);

    this.checkBookingExpiration();
  }

  checkBookingExpiration() {
    if (!this.data.has("check-expiration-url")) {
        return;
    }
    const url = this.data.get("check-expiration-url");

    setInterval(() => {
      fetch(url)
        .then(response => response.json())
        .then(data => {
          if (data.expired) {
            location.reload();
          }
        });
    }, 60000); // 60000 ms = 1 minute
  }

  inputFocusHandler() {
    const that = this;
    [
      this.cardNumberTarget,
      this.cardCVVTarget,
      this.cardMonthTarget,
      this.cardYearTarget,
      this.cardBrandTarget,
    ].forEach(function (element, idx) {
      element.addEventListener("focus", function (event) {
        that.selectNewCard();
      });
    });
  }

  // submitting the payment form after validating the form
  submit(event) {
    event.preventDefault();
    if (this.validate) {
      Rails.disableElement(this.submitTarget);

      $(this.datatransFormTarget).trigger("submit.rails");
    }
  }

  get validate() {
    $(this.datatransFormTarget).parsley().reset();
    if (this.selectedPaymentCard === "false") {
      this.paymentMethodTarget.value = this.cardBrandTarget.value;
      return $(this.datatransFormTarget).parsley().validate();
    } else if (this.selectedPaymentCard === "isr") {
      this.datatransFormTarget.action = this.datatransFormTarget.dataset.postfinanceInvoiceUrl;
      return $(this.datatransFormTarget).parsley().validate({ group: "term" });
    } else {
      this.paymentMethodTarget.value = this.selectedPaymentCard;
      return $(this.datatransFormTarget).parsley().validate({ group: "term" });
    }
  }

  get locale() {
    const lang = this.data.get("locale");

    if (lang === "it") return "en";
    return lang;
  }
}
