import { Controller } from "stimulus";
import $ from "jquery";
import Autonumeric from "autonumeric";

export default class extends Controller {
  static targets = [
    "modal",
  ];

  connect() {
    this.selectProjectTemplate();
    if (this.shouldOpenModal()) {
      this.openModal();
    }
    this.initializeControls();
    this.initializeInputs();
    this.startValidationMonitor();
    this.scrollToErrorMonitor();
    this.initializeAddButtons();
  }

  selectProjectTemplate() {
    if (window.location.search.includes('select_template_id')) {
      let templateId = new URLSearchParams(window.location.search).get('select_template_id');
      let base_url = window.location.pathname;
      let input = document.querySelector("#booking_brand_id_" + templateId);

      if (window.location.pathname.includes('cart')) {
        let url = base_url.replace('project_details', 'select_brand.js?brand_id=' + templateId)
        $.ajax({url})
        input.checked = true;
      }
      else {
        let url = base_url.replace('edit', 'select_brand.js?brand_id=' + templateId)
        $.ajax({url})
        input.checked = true;
      }

      window.history.replaceState({}, document.title,window.location.href.split("?")[0]);
    }
  }

  shouldOpenModal() {
    return window.location.search.includes('modal_form_create_ai=show') ||
           window.location.search.includes('modal_form_ai=show') ||
           window.location.search.includes('modal_form=show');
  }

  openModal() {
    if (this.hasModalTarget && this.modalTarget?.getAttribute('id').includes("create") && window.location.search.includes('modal_form_create_ai=show')) {
      $('#modal__ai_project_template_create').modal('show');
    }
    else {
      if (this.hasModalTarget && this.modalTarget.getAttribute('id') !== 'modal__ai_project_template_create') {
        $("#" + this.modalTarget.getAttribute('id')).modal("show")
        let FirstSection = document.querySelector('.section.collapse')
        FirstSection?.classList.add("show");
      }
    }

    setTimeout(function() {
      window.history.replaceState({}, document.title,window.location.href.split("?")[0]);
    },0);
  }

  initializeAddButtons() {
    const addButtons = document.querySelectorAll('[data-modal-target]');
    const sectionWrapperBlocks = document.querySelectorAll('.accordion-section-wrapper');
    addButtons.forEach((button) => {
      button.addEventListener("click", () => {
        sectionWrapperBlocks.forEach(sectionWrapper => {
          let sectionBlock = sectionWrapper.children[0]
          sectionWrapper.classList.add('collapsed');
          sectionBlock.classList.remove('show');
          sectionBlock.classList.remove('collapsed');
        })
        if (document.querySelector(button.dataset.modalTarget)) {
          let currentSectionWrapper = document.querySelector(button.dataset.modalTarget)?.closest(".accordion-section-wrapper");
          let currentSection = document.querySelector(button.dataset.modalTarget)?.closest(".section");
          let currentField = document.querySelector(button.dataset.modalTarget)?.closest(".field");
          if (currentSectionWrapper !== null) {
            currentSectionWrapper?.classList.remove("collapsed");
            currentSectionWrapper?.classList.add("show");
            currentSectionWrapper?.children[0].classList.remove('collapsed');
          }
          if (currentSection !== null) {
            currentSection?.classList.add("show");
          }

          if (currentField !== null && button.dataset.scrollOn === "true") {
            setTimeout(function () {
              currentField.scrollIntoView({behavior: 'smooth', block: 'start'})
            }, 400);
          }

          if (currentSectionWrapper !== null && currentField !== null) {
            setTimeout(function () {
              const sectionNames = ["brands", "concept", "product_service", "social_media"];

              if (sectionNames.includes(button.dataset.scroll)) {
                currentSectionWrapper?.scrollIntoView({behavior: 'smooth', block: 'start'});
              } else {
                currentField?.scrollIntoView({behavior: 'smooth', block: 'start'})
              }
            }, 400);
          }
        }
      });
    });
  }

  initializeControls() {
    const saveButtons = document.querySelectorAll('#' + this.element.id + ' .submit-btn');
    saveButtons.forEach((button) => {
      button.addEventListener("click", () => {
        this.saveProjectTemplate();
      });
    });
  }

  initializeInputs() {
    const input = document.querySelector('input.select-text');
    if (input !== null) {
      input.addEventListener("click", () => {
        input.focus();
        input.select();
      });
    }
    $('[data-toggle="tooltip"]').tooltip();
  }

  updateSectionIndicator(section) {
    function validateImageResolution(logoUploaderId, width, height) {
      return new Promise((resolve, reject) => {
        const logoUploaderDiv = document.getElementById(logoUploaderId).querySelector('.preview');
        const computedStyle = window.getComputedStyle(logoUploaderDiv);
        const backgroundImageUrl = computedStyle.getPropertyValue('background-image');

        if (backgroundImageUrl === 'url("")') {
          resolve(true);
          return;
        }

        const decodedUrl = decodeURIComponent(backgroundImageUrl.replace(/url\("(.+?)"\)/, '$1'));
        const img = new Image();
        img.src = decodedUrl;

        img.onload = function() {
          resolve(this.width >= width && this.height >= height);
        };

        img.onerror = () => {
          resolve(false);
        };
      });
    }

    function validateURL(urlString) {
      if (urlString && !/^https?:\/\//i.test(urlString)) {
        urlString = 'https://' + urlString;
      }

      const URL_REGEX = /^((https?):\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i;

      const match = urlString.match(URL_REGEX);
      return urlString.includes(' ') ? false : match && match[0].length <= 200;
    }

    const validatedInputs = Array.from(section.querySelectorAll('[data-validate="true"]'));
    const validationsPromises = validatedInputs.map(input => {
      if (input.name === 'project_template[description]') {
        return (input.value.length > 200);
      }
      if (input.name === 'project_template[website]') {
        if (input.attributes['data-required']?.value === 'false') {
          return input.value === "" ? true : validateURL(input.value);
        }
        else {
          return validateURL(input.value);
        }
      }
      if (input.name === 'project_template[instagram_id]') {
        return input.value === "" ? true : validateURL(input.value);
      }
      if (input.name === 'project_template[linkedin_id]') {
        return input.value === "" ? true : validateURL(input.value);
      }
      if (input.name === 'project_template[facebook_id]') {
        return input.value === "" ? true : validateURL(input.value);
      }
      if (input.name === 'project_template[twitter_id]') {
        return input.value === "" ? true : validateURL(input.value);
      }
      if (input.name === 'project_template[year_founded]') {
        return (input.value.length >= 4);
      }
      if (input.name === 'project_template[overall_look]') {
        return (input.value.length >= (input.rows > 3 ? 200 : 4));
      }
      if (input.name === 'project_template[overall_look]') {
        return (input.value.length >= 200);
      }
      if (input.name === 'project_template[shops_experience]') {
        const inputElement = document.querySelector('#' + this.element.id + ' .active input[name="project_template[shops_experience]"]')?.value;
        return (document.querySelector('#' + this.element.id + ' #project_template_shops_experience')?.type === "text" ?
            document.querySelector('#' + this.element.id + ' #project_template_shops_experience').value.length >= 4 :
            (inputElement === 'true' || inputElement === 'false'));
      }
      if (input.name === 'project_template[existing_shops_info]') {
        return (input.value.length >= 4);
      }
      if (input.name === 'project_template[popups_experience]') {
        const inputElement = document.querySelector('#' + this.element.id + ' .active input[name="project_template[popups_experience]"]')?.value;
        return (document.querySelector('#' + this.element.id + ' #project_template_popups_experience')?.type === "text" ?
            document.querySelector('#' + this.element.id + ' #project_template_popups_experience').value.length >= 4 :
            (inputElement === 'true' || inputElement === 'false'));
      }
      if (input.name === 'project_template[prices_from]') {
        let pricesTo = document.querySelector('#' + this.element.id + ' #project_template_prices_to').value;
        let pricesToValue = pricesTo !== '' ? parseInt(pricesTo?.replace(/[.,]/g, '').match(/\d+/)[0]) : '';
        if (input.value !== '' && input.value !== null && pricesToValue !== '') {
        let pricesFrom = parseInt(input.value?.replace(/[.,]/g, '').match(/\d+/)[0]);
          return (pricesFrom >= 0 && pricesToValue >= (pricesFrom));
        }
      }
      if (input.name === 'project_template[prices_to]') {
        let pricesFrom = document.querySelector('#' + this.element.id + ' #project_template_prices_from').value;
        let pricesFromValue = pricesFrom !== '' ? parseInt(pricesFrom?.replace(/[.,]/g, '').match(/\d+/)[0]) : '';
        if (input.value !== '' && input.value !== null && pricesFromValue !== '') {
          let pricesTo = parseInt(input.value?.replace(/[.,]/g, '').match(/\d+/)[0]);
          return (pricesTo >= pricesFromValue && pricesTo !== 0);
        }
      }
      if (input.name === 'project_template[tags][]') {
        let tagsArray = Array.from(document.querySelectorAll('#' + this.element.id + ' .project_template_tags option'));
        let tagsAnySelected = tagsArray.some(tag => tag['selected']);

        return tagsAnySelected
      }
      if (input.name === 'project_template[industry][]') {
        let industryArray = Array.from(document.querySelectorAll('#' + this.element.id + ' .project_template_industry option'));
        let industryAnySelected = industryArray.some(industry => industry['selected']);

        return industryAnySelected
      }
      if (input.name === 'brand[logo]') {
        if (document.querySelector('#' + this.element.id + ' .save-btn')?.attributes["data-form-name"].nodeValue !== 'full_new_ai') {
          return validateImageResolution('logo-fileupload-brand-logo', 600, 600);
        }
        else {
          return true;
        }
      }
      if (input.name === 'brand[image]') {
        if (document.querySelector('#' + this.element.id + ' .save-btn')?.attributes["data-form-name"].nodeValue !== 'full_new_ai') {
          return validateImageResolution('logo-fileupload-brand-image', 1200, 800);
        }
        else {
          return true;
        }
      }
      return !!input.value;
    });

    Promise.all(validationsPromises).then(results => {
      const filled = results.every(result => result);
      const errorFields = Array.from(section.querySelectorAll('.input__error'));
      validatedInputs.forEach((input, index) => {
        const errorField = errorFields.find(field => (field.id) === `${input.id.replace(/(_true|_false)$/, '').replace(/brand\[(.*?)]/, 'project_template_$1')}_error`);
        if (errorField) {
          if (results[index]) {
            errorField.innerHTML = "";
            errorField.classList.add("is-hidden");
          }
        }
      });
      const sectionHeader = document.querySelector('#' + this.element.id + ` .section__header[data-target="#${section.getAttribute("id")}"]`);
      if (filled && sectionHeader) {
        section.querySelectorAll('#' + this.element.id + ' .input__error').forEach((errorHolder) => {
          errorHolder.innerHTML = "";
          errorHolder.classList.add("is-hidden");
        });
        sectionHeader.classList.remove("invalid");
        sectionHeader.classList.add("filled");
      } else if (sectionHeader) {
        sectionHeader.classList.remove("filled");
      }
    });
  }

  startValidationMonitor() {
    const that = this;
    this.validationTimer = setInterval(() => {
      document
          .querySelectorAll('#' + this.element.id + ' .section')
          .forEach((section) => {
            that.updateSectionIndicator(section);
          });

      let instagramUrlInput = document.getElementById('instagram_url')
      let photosLength = document.querySelectorAll('#product_services_section .template-download').length
      let instagramHolder = document.getElementById('instagram_photos_holder');
      if (instagramUrlInput != null) {
        if (photosLength > 0) {
          instagramUrlInput.closest('.field').style.display = 'none';
        }
        if (photosLength === 0) {
          instagramUrlInput.closest('.field').style.display = '';
          instagramHolder.parentElement.classList.add('d-none');
        }
      }
    }, 300);
  }

  scrollToErrorMonitor() {
    const submitButton = document.getElementById('submit-inquiry');

    submitButton?.addEventListener('click', function (e) {
      setTimeout(function () {
        let error = document.querySelector('ul.parsley-errors-list.filled');

        if (error) {
          error.closest('.section-block').scrollIntoView({behavior: 'smooth'});
        }
      }, 100);
    });
  };

  saveProjectTemplate() {
    event.preventDefault();
    const form = document.querySelector('#' + this.element.id + ' #project_template_form');
    const method = form.getAttribute("data-method");
    const action = form.getAttribute("data-action");
    const formName = event.target.attributes["data-form-name"].nodeValue;

    let dataToSend = {};

    let tagsArray = document.querySelectorAll('#' + this.element.id + ' .project_template_tags option');
    const selectedTags = [];
    tagsArray.forEach(tag => {
      if (tag['selected']) {
        selectedTags.push(tag.value);
      }
    });

    let industryArray = document.querySelectorAll('#' + this.element.id + ' .project_template_industry option');
    const selectedIndustry = [];
    industryArray.forEach(industry => {
      if (industry['selected']) {
        selectedIndustry.push(industry.value);
      }
    });

    if (formName === "brands") {
      let website = document.querySelector('#' + this.element.id + ' #project_template_website').value;

      if (website && !/^https?:\/\//i.test(website)) {
        website = 'https://' + website;
      }

      dataToSend = {
        name: document.querySelector('#' + this.element.id + ' #project_template_name').value,
        tags: selectedTags,
        title: document.querySelector('#' + this.element.id + ' #project_template_title').value,
        description: document.querySelector('#' + this.element.id + ' #project_template_description').value,
        employee_count: document.querySelector('#' + this.element.id + ' #project_template_employee_count').value,
        year_founded: document.querySelector('#' + this.element.id + ' #project_template_year_founded').value,
        based: document.querySelector('#' + this.element.id + ' #project_template_based').value,
        website: website,
      };
    } else if (formName === "concepts") {
      dataToSend = {
        industry: selectedIndustry,
        overall_look: document.querySelector('#' + this.element.id + ' #project_template_overall_look').value.replace(/(\r\n|\n|\r)/gm, " "),
        shops_experience: document.querySelector('#' + this.element.id + ' #project_template_shops_experience')?.type === "text" ?
                          document.querySelector('#' + this.element.id + ' #project_template_shops_experience').value :
                          document.querySelector('.active input[name="project_template[shops_experience]"]')?.value,
        existing_shops_info: document.querySelector('#' + this.element.id + ' #project_template_existing_shops_info').value,
        popups_experience: document.querySelector('#' + this.element.id + ' #project_template_popups_experience')?.type === "text" ?
            document.querySelector('#' + this.element.id + ' #project_template_popups_experience').value :
            document.querySelector('#' + this.element.id + ' .active input[name="project_template[popups_experience]"]')?.value
      };
    } else if (formName === "product_services") {
      let pricesFromValue = document.querySelector('#' + this.element.id + ' #project_template_prices_from').value
      let pricesToValue = document.querySelector('#' + this.element.id + ' #project_template_prices_to').value
      dataToSend = {
        prices_from: pricesFromValue !== '' ? parseInt(pricesFromValue?.replace(/[.,]/g, '').match(/\d+/)[0].slice(0, 7)) : NaN,
        prices_to: pricesToValue !== '' ? parseInt(pricesToValue?.replace(/[.,]/g, '').match(/\d+/)[0].slice(0, 7)) : NaN,
      };
    } else if (formName === "social_media") {
      let instagram = document.querySelector('#' + this.element.id + ' #project_template_instagram_id').value;
      if (instagram && !/^https?:\/\//i.test(instagram)) {
        instagram = 'https://' + instagram;
      }

      let linkedin = document.querySelector('#' + this.element.id + ' #project_template_linkedin_id').value;
      if (linkedin && !/^https?:\/\//i.test(linkedin)) {
        linkedin = 'https://' + linkedin;
      }

      let facebook = document.querySelector('#' + this.element.id + ' #project_template_facebook_id').value;
      if (facebook && !/^https?:\/\//i.test(facebook)) {
        facebook = 'https://' + facebook;
      }

      let twitter = document.querySelector('#' + this.element.id + ' #project_template_twitter_id').value;
      if (twitter && !/^https?:\/\//i.test(twitter)) {
        twitter = 'https://' + twitter;
      }

      dataToSend = {
        instagram_id: instagram,
        linkedin_id: linkedin,
        facebook_id: facebook,
        twitter_id: twitter,
      };
    } else if (formName === "instagram_photos") {
        let instagram = document.querySelector('#' + this.element.id + ' #instagram_url').value;
        let instagramErrorHolder = document.querySelector('#project_template_instagram_url_error');
        if (instagram && !/^https?:\/\//i.test(instagram)) {
            instagram = 'https://' + instagram;
        }

        if (instagram === "" || !instagram.includes('instagram.com')) {
          instagramErrorHolder.innerHTML = instagramErrorHolder.getAttribute('data-error-text')
          instagramErrorHolder.classList.remove("is-hidden");
        }

        else {
          instagramErrorHolder.innerHTML = ""
          instagramErrorHolder.classList.add("is-hidden");
          dataToSend = {
              instagram_id: instagram,
          };
        }
    } else if (formName === "active") {
      dataToSend = {
        is_active: document.querySelector('#' + this.element.id + ' #set_active').checked,
      }
    } else if (formName === "partial_new" || formName === "partial_new_ai") {
      let manualModeBtn = document.querySelector('.btn-ai-manually');
      let website = document.querySelector('#' + this.element.id + ' #project_template_website').value;

      if (website && !/^https?:\/\//i.test(website)) {
        website = 'https://' + website;
      }

      dataToSend = {
        title: document.querySelector('#' + this.element.id + ' #project_template_title').value ?
            document.querySelector('#' + this.element.id + ' #project_template_title').value :
            manualModeBtn.getAttribute('data-project-template-name'),
        website: website,
        name: document.querySelector('#' + this.element.id + ' #project_template_name').value,
        tags: selectedTags,
        industry: selectedIndustry,
        overall_look: document.querySelector('#' + this.element.id + ' #project_template_overall_look').value.replace(/(\r\n|\n|\r)/gm, " ")
      };
    } else {
      let website = document.querySelector('#' + this.element.id + ' #project_template_website').value;

      if (website && !/^https?:\/\//i.test(website)) {
        website = 'https://' + website;
      }

      let instagram = document.querySelector('#' + this.element.id + ' #project_template_instagram_id').value;
      if (instagram && !/^https?:\/\//i.test(instagram)) {
        instagram = 'https://' + instagram;
      }

      let linkedin = document.querySelector('#' + this.element.id + ' #project_template_linkedin_id').value;
      if (linkedin && !/^https?:\/\//i.test(linkedin)) {
        linkedin = 'https://' + linkedin;
      }

      let facebook = document.querySelector('#' + this.element.id + ' #project_template_facebook_id').value;
      if (facebook && !/^https?:\/\//i.test(facebook)) {
        facebook = 'https://' + facebook;
      }

      let twitter = document.querySelector('#' + this.element.id + ' #project_template_twitter_id').value;
      if (twitter && !/^https?:\/\//i.test(twitter)) {
        twitter = 'https://' + twitter;
      }

      let pricesFromValue = document.querySelector('#' + this.element.id + ' #project_template_prices_from').value
      let pricesToValue = document.querySelector('#' + this.element.id + ' #project_template_prices_to').value

      dataToSend = {
        name: document.querySelector('#' + this.element.id + ' #project_template_name').value,
        tags: selectedTags,
        title: document.querySelector('#' + this.element.id + ' #project_template_title').value,
        description: document.querySelector('#' + this.element.id + ' #project_template_description').value,
        employee_count: document.querySelector('#' + this.element.id + ' #project_template_employee_count').value,
        year_founded: document.querySelector('#' + this.element.id + ' #project_template_year_founded').value,
        based: document.querySelector('#' + this.element.id + ' #project_template_based').value,
        website: website,
        form_name: event.target.attributes["data-form-name"].nodeValue,
        overall_look: document.querySelector('#' + this.element.id + ' #project_template_overall_look').value.replace(/(\r\n|\n|\r)/gm, " "),
        shops_experience: document.querySelector('#' + this.element.id + ' #project_template_shops_experience')?.type === "text" ?
            document.querySelector('#' + this.element.id + ' #project_template_shops_experience').value :
            document.querySelector('#' + this.element.id + ' .active input[name="project_template[shops_experience]"]')?.value,
        existing_shops_info: document.querySelector('#' + this.element.id + ' #project_template_existing_shops_info').value,
        popups_experience: document.querySelector('#' + this.element.id + ' #project_template_popups_experience')?.type === "text" ?
            document.querySelector('#' + this.element.id + ' #project_template_popups_experience').value :
            document.querySelector('#' + this.element.id + ' .active input[name="project_template[popups_experience]"]')?.value,
        industry: selectedIndustry,
        prices_from: pricesFromValue !== '' ? parseInt(pricesFromValue?.replace(/[.,]/g, '').match(/\d+/)[0].slice(0, 7)) : NaN,
        prices_to: pricesToValue !== '' ? parseInt(pricesToValue?.replace(/[.,]/g, '').match(/\d+/)[0].slice(0, 7)) : NaN,
        instagram_id: instagram,
        linkedin_id: linkedin,
        facebook_id: facebook,
        twitter_id: twitter
      };
    }

    const fieldDescriptionHtmlCode = `
    <div class="flex flex-col field field-ai-description rounded bg-gray-150 m-0 p-4 animate-pulse">
      <div class="h-4 bg-gray-200 rounded w-full"></div>
      <div class="h-14 bg-gray-200 rounded w-full mt-3"></div>
    </div>`;

    const inputFieldsHtmlCode = `
    <div class="pt-4 animate-pulse">
      <div class="h-4 bg-gray-200 rounded w-1/5"></div>
      <div class="h-11 bg-gray-200 rounded w-full mt-1"></div>
      <div class="h-4 bg-gray-200 rounded w-1/3 mt-1"></div>
    </div>`;

    const textAreaHtmlCode = `
    <div class="pt-4 animate-pulse">
      <div class="h-4 bg-gray-200 rounded w-1/4"></div>
      <div class="h-24 bg-gray-200 rounded mt-1"></div>
      <div class="h-4 bg-gray-200 rounded w-1/3 mt-1"></div>
    </div>`;

    const buttonHtmlCode = `<div class="h-10 animate-btn bg-gray-200 rounded w-48 ml-4 mt-1"></div>`;
    const projectName = document.querySelector('#' + this.element.id + ' #project_template_name')?.value
    const projectTemplateId = document.querySelector('#' + this.element.id + ' #project_template_id')?.value

    $.ajax({
      url: action,
      type: method,
      dataType: "json",
      data: {
        project_template: dataToSend,
        form_name: formName,
        project_name: projectName,
        project_template_id: projectTemplateId,
      },
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      beforeSend: function() {
        document.querySelector('.modal-content')?.scrollIntoView({behavior: 'smooth', block: 'start'})
        document.querySelector('.loader')?.classList.remove('is-hidden')
        const descriptionField = document.querySelector('.field.field-ai-description');
        const inputFields = document.querySelectorAll('.field.text');
        const textAreaFields = document.querySelectorAll('.field.text-area');
        const buttonFields = document.querySelectorAll('.submit-btn.animated');
        if (descriptionField) {
          descriptionField.style.display = 'none';
        }
        descriptionField?.insertAdjacentHTML('beforebegin', fieldDescriptionHtmlCode);
        inputFields?.forEach(field => {
          field.style.display = 'none';
          field.insertAdjacentHTML('beforebegin', inputFieldsHtmlCode);
        });
        textAreaFields?.forEach(field => {
          field.style.display = 'none';
          field.insertAdjacentHTML('beforebegin', textAreaHtmlCode);
        });
        buttonFields?.forEach(field => {
          field.style.display = 'none';
          field.insertAdjacentHTML('beforebegin', buttonHtmlCode);
        });
      }
    }).done((data) => {
      if (data?.project_template?.id) {
        let form = document.querySelector('#modal__global_new #project_template_form');
        form.setAttribute("data-action", action.replace('pull_brand_data', data?.project_template?.id));
      }

      if (data?.error) { alert(data?.error) }

      if (data?.logo?.files?.length > 0) {
        const logos = document.querySelectorAll('#logo-fileupload-brand-logo .preview');
        logos.forEach(logo => {
          if (!data.logo.files[0].url.includes('missing')) {
            logo.classList.remove('empty')
            logo.children[0]?.classList.remove('icon-photo')
            logo.nextElementSibling.children[0].style.display = 'none';
            logo.nextElementSibling.children[1].style.display = 'block';
            logo.style.backgroundImage = `url('${data.logo.files[0].url}')`;
          }
        })
      }

      if (data?.backgroundImage?.files?.length > 0) {
        const images = document.querySelectorAll('#logo-fileupload-brand-image .preview');
        images.forEach(image => {
          if (!data.backgroundImage.files[0].url.includes('missing')) {
            image.classList.remove('empty')
            image.children[0]?.classList.remove('icon-photo')
            image.nextElementSibling.children[0].style.display = 'none';
            image.nextElementSibling.children[1].style.display = 'block';
            image.style.backgroundImage = `url('${data.backgroundImage.files[0].url}')`;
          }
        })
      }

      if (data?.photos?.files?.length > 0) {
        let instagramUrlInputValue = document.getElementById('instagram_url')?.value
        let instagramIdInput = document.getElementById('project_template_instagram_id')
        if (instagramUrlInputValue && instagramIdInput) {
          instagramIdInput.value = instagramUrlInputValue;
        }

        function renderFiles(files) {
          files.forEach(file => {
            const fileDiv = document.createElement('div');
            let thumbnailUrl = file.thumbnailUrl.replace('attachments','brand_images');
            let attachmentTemplate = document.querySelector("#attachment-template-download").innerHTML;
            let tempContainer = document.createElement('div');
            tempContainer.innerHTML = attachmentTemplate;
            let Title = tempContainer.querySelector("input[name='description']").placeholder;
            let Update = tempContainer.querySelector('.btn.update span').innerText
            let Delete = tempContainer.querySelector('.btn.btn-danger span').innerText

            fileDiv.className = 'template-download in';
            fileDiv.setAttribute('data-id', file.id);

            fileDiv.innerHTML = `
              <div class="fileupload-preview grid-container col-gap-0-5">
                  <div class="preview grid-3">
                      <img src="${thumbnailUrl}" alt="${file.name}">
                  </div>
                  <div class="preview__data grid-9">
                      <div class="preview__options text-left">
                          <input required="required" minlength="3" class="input" placeholder="${Title}" name="description" value="${file.description ? file.description?.trim() : ''}">
                          <div class="description">${file.description ? file.description?.trim() : ''}</div>
                          <div class="size">${(file.size / 1024).toFixed(2)} KB</div>
                          <div>
                          <button class="btn btn-primary start" type="button" data-remote="true"
                             onclick="event.preventDefault(); 
                                      const description = this.closest('.template-download').querySelector('input')?.value?.trim();
                                      const description_label = this.closest('.template-download').querySelector('.description')
                                      const csrfToken = document.querySelector('meta[name=\\'csrf-token\\']').getAttribute('content');
                                      const data = new URLSearchParams();
                                      data.append('description', description);
                                      fetch('${file.updateUrl}', {
                                          method: 'PATCH',
                                          headers: {
                                            'Accept': 'application/json',
                                            'Content-Type': 'application/x-www-form-urlencoded',
                                            'X-CSRF-Token': csrfToken
                                          },
                                          body: data.toString()
                                      }).then(() => {
                                          description_label.innerText = description;
                                        }).catch(error => console.error('Error:', error));">
                                <i class="icon-arrow-up">&nbsp;</i>
                                <span>${Update}</span>
                              </button>
                              <button class="btn btn-secondary btn-warning cancel" type="button" data-remote="true"
                                 onclick="event.preventDefault(); 
                                          const csrfToken = document.querySelector('meta[name=\\'csrf-token\\']').getAttribute('content');
                                          fetch('${file.deleteUrl}', { 
                                              method: 'DELETE',
                                              headers: {
                                                  'X-CSRF-Token': csrfToken
                                              }}).then(() => {
                                                  this.closest('.template-download').remove();
                                          })">
                                <i class="icon-trash-can">&nbsp;</i>
                                <span>${Delete}</span>
                              </button>
                          </div>
                      </div>
                  </div>
              </div>
            `;
            const container = document.querySelector('#instagram_photos_holder');
            const elements = document.querySelectorAll('#attachment_files .template-download');
            elements?.forEach(file => {
              file.remove();
            });
            container.parentElement.classList.remove('d-none')
            container.appendChild(fileDiv);
            container.parentElement.scrollIntoView({behavior: 'smooth', block: 'start'});
          });
        }

        renderFiles(data.photos.files);
      }

      document.querySelector('.loader')?.classList.add('is-hidden')
      const fields = document.querySelectorAll('.field, .animated');
      const animated_elements = document.querySelectorAll('.animate-pulse, .animate-btn');
      fields?.forEach(field => {
        field.style.display = '';
      });
      animated_elements?.forEach(element => {
        element.remove()
      });
      if (data && data.errors) {
        this.showErrorNotifications(data.errors);
      } else {
        if (data && data.project_template && data.project_template.id !== null) {
          let submitButtons = document.querySelectorAll('#modal__global_new .submit-btn')
          submitButtons.forEach(btn => {
            btn.setAttribute('data-project-template-id', data.project_template?.id);
          })
        }

        let projectTemplateId = document.querySelector('#modal__global_new .submit-btn').getAttribute('data-project-template-id');

        function clearAndSetTagsIndustry(selectFields, newValues) {
          selectFields.forEach(field => {
            $(field).val(newValues).trigger('change');
          })
        }

        function setExperience(baseSelector, experienceValue, experienceType) {
          if (experienceValue === "true" || experienceValue === "false") {
            $(`${baseSelector} #project_template_${experienceType}_${experienceValue}`).parent().addClass('active');
          }
        }

        function autofillForm(data, formName) {
          let baseSelector = '#modal__global_new'
          $(baseSelector).modal('show');
          let dataFormName = (formName === 'partial_new_ai' ? 'full_new_ai' : 'global_new')
          let submitButton = document.querySelectorAll(`.submit-btn[data-form-name="global_new"], .submit-btn[data-form-name="full_new_ai"]`);

          submitButton.forEach(btn => {
            btn.attributes['data-form-name'].value = dataFormName
          })

          if (formName === 'partial_new_ai') {
            document.querySelector('.field-description').classList.remove('is-hidden')
          } else {
            document.querySelector('.field-description').classList.add('is-hidden')
          }

          let FirstSection = document.querySelector(baseSelector + ' .section.collapse')
          FirstSection?.classList.add("show");

          let manualModeBtn = document.querySelector('.btn-ai-manually');
          let allAutofilled = false;

          if (manualModeBtn.hasAttribute('data-autofilled') && manualModeBtn.getAttribute('data-autofilled') === 'true') {
            allAutofilled = true;
          }

          function reinitializeUploadComponent(projectTemplateId) {
            const logo = document.getElementById('logo-fileupload-brand-logo');
            const image = document.getElementById('logo-fileupload-brand-image');
            const imagesPhotos = document.querySelectorAll('#attachment-fileupload');

            const initializeFileUpload = (element, attributeName) => {
              const newUrl = element.getAttribute(attributeName).replace('-1', projectTemplateId);
              element.setAttribute(attributeName, newUrl);

              $(element).fileupload({
                url: newUrl,
                dataType: 'json',
                autoUpload: true,
                acceptFileTypes: /(\.|\/)(gif|jpe?g|png|bmp|tiff|webp)$/i,
                maxFileSize: 25000000
              });
            };

            initializeFileUpload(logo, 'data-logo-upload-url');
            initializeFileUpload(image, 'data-logo-upload-url');

            imagesPhotos.forEach(photo => {
              const newUrl = photo.getAttribute('data-url').replace('-1', projectTemplateId);
              photo.setAttribute('data-url', newUrl);

              $(photo).fileupload({
                url: newUrl,
                dataType: 'json',
              });
            });
          }

          const uploaderUrl = document.getElementById('logo-fileupload-brand-logo').getAttribute('data-logo-upload-url');
          if (uploaderUrl.includes('-1')) {
            reinitializeUploadComponent(projectTemplateId);
          }

          if (allAutofilled === false) {
            let $tags = document.querySelectorAll("[name='project_template[tags][]']")
            let $industries = document.querySelectorAll("[name='project_template[industry][]']")

            clearAndSetTagsIndustry($tags, dataToSend.tags);
            clearAndSetTagsIndustry($industries, dataToSend.industry);

            setExperience(baseSelector, data.shops_experience, 'shops_experience');
            setExperience(baseSelector, data.popups_experience, 'popups_experience');

            const fields = [
              'title', 'description', 'name', 'employee_count', 'website', 'year_founded', 'based',
              'overall_look', 'existing_shops_info', 'instagram_id', 'linkedin_id', 'facebook_id', 'twitter_id'
            ];
            const fields_with_cents = [
              'prices_from_cents', 'prices_to_cents'
            ];

            fields.forEach(field => {
              $(`${baseSelector} #project_template_${field}`).val(data[field]).trigger('change');
            });

            fields_with_cents.forEach(field => {
              let element = document.querySelector(`${baseSelector} #project_template_${field.replace('_cents', '')}`)

              const currency = element.dataset.currency.toUpperCase();
              const decimalMark = element.dataset.decimalMark;
              const thousandsSeparator = element.dataset.thousandsSeparator;
              const initialValue = data[field] / 100;

              const anElement = new Autonumeric(element, {
                digitGroupSeparator: thousandsSeparator,
                decimalCharacter: decimalMark,
                currencySymbolPlacement: Autonumeric.options.currencySymbolPlacement.prefix,
                currencySymbol: `${currency} `,
                minimumValue: 0,
                decimalPlaces: 0
              });

              anElement.set(initialValue);
            });

            if (dataFormName === 'full_new_ai') {
              let WebsiteFields = document.querySelectorAll('#project_template_website');

              WebsiteFields.forEach(website => {
                website.dataset['required'] = "true"
              })
            }

            if (dataFormName === 'global_new') {
              let WebsiteFields = document.querySelectorAll('#project_template_website');

              WebsiteFields.forEach(website => {
                website.dataset['required'] = "false"
              })
            }

            document.querySelector('.btn-ai-manually').setAttribute('data-autofilled', 'true');
          }
        }

        if (formName === "global_new") {
          if (window.location.pathname.includes('booking') || window.location.pathname.includes('cart')) {
            history.replaceState(null, '', '?select_template_id=' + projectTemplateId);
            window.location.reload();
          }

          const redirect_path = document.querySelector('#' + this.element.id + ' .submit-btn').getAttribute('data-redirect-path');

          if (redirect_path === "edit") {
            Turbolinks.visit((window.location.pathname.endsWith('/') ? window.location.pathname : window.location.pathname + '/') + projectTemplateId + '/edit');
          }

          if (!window.location.pathname.includes('new')){
            Turbolinks.visit(window.location.pathname + '/' + projectTemplateId + '/edit')
          }

          else {
            Turbolinks.visit(window.location.pathname.replace('new', projectTemplateId + '/edit'))
          }
        } else if (formName === "instagram_photos") {
        } else if (formName === "full_new_ai") {
          if (window.location.pathname.includes('booking') || window.location.pathname.includes('cart')) {
            history.replaceState(null, '', '?select_template_id=' + projectTemplateId);
            window.location.reload();
          }
          else {
            Turbolinks.visit((window.location.pathname.endsWith('/') ? window.location.pathname : window.location.pathname + '/') + projectTemplateId + '/edit');
          }
        }
        else if (formName === "partial_new" || formName === "partial_new_ai") {
          autofillForm(data.project_template, formName);
        }
        else if (window.location.pathname.includes('booking') || window.location.pathname.includes('cart')) {
          window.location.hash = '#booking-form-component';
          window.location.reload();
        }
        else {
          window.location.reload();
        }
      }
    }).fail(function() {
      document.querySelector('.loader')?.classList.add('is-hidden')
      const fields = document.querySelectorAll('.field');
      const animated_elements = document.querySelectorAll('.animate-pulse, .animate-btn');
      fields?.forEach(field => {
        field.classList.remove('is-hidden')
      });
      animated_elements?.forEach(element => {
        element.remove()
      });
    })
  }

  showErrorNotifications(errors) {
    document.querySelectorAll('#' + this.element.id + ' .modal__project_template .input__error').forEach((errorHolder) => {
      errorHolder.innerHTML = "";
      errorHolder.classList.add("is-hidden");
    });

    document.querySelectorAll('#' + this.element.id + ' .modal__project_template .accordion-section').forEach((section) => {
      section.classList.remove("invalid");
    });

    Object.entries(errors).forEach((error) => {
      const fieldName = error[0];
      const errorMessage = error[1].join(', ');
      const errorHolder = document.querySelector('#' + this.element.id + ` #project_template_${fieldName}_error`);
      const section = document.querySelector('#' + this.element.id + ` .accordion-section.${errorHolder?.getAttribute("data-section")}`);
      section?.classList.add("invalid");
      errorHolder?.classList.remove("is-hidden");
      document.querySelectorAll('#' + this.element.id + ' #modal__global_new .collapse').forEach((section) => {
        section.classList.remove('show')
        section.parentElement.classList.add('collapsed')
      });
      if (section) {
        document.querySelector('#' + this.element.id + ' .invalid').parentElement.querySelector('.collapse').classList.add('show')
        document.querySelector('#' + this.element.id + ' .collapse').closest('form .collapse')?.classList.add('show')
      }
      errorHolder?.parentElement.parentElement.parentElement.classList.add("show");
      let closestElement = errorHolder?.closest('form .collapse');
      if (closestElement !== null) {
        errorHolder?.closest('form .collapse').classList.add("show");
      }
      if (errorHolder) {
        errorHolder.classList.remove("collapsed");
        errorHolder.parentElement.classList.remove("collapsed");
        errorHolder.innerHTML = errorMessage;
      }
      let first_error_input = document.querySelector('#' + this.element.id + ' .invalid');
      if (first_error_input !== null) {
        first_error_input.scrollIntoView({behavior: 'smooth', block: 'start'});
      }
    });
  }
}
